module.exports = {
  siteTitle: 'Best Movers', // <title>
  manifestName: 'Best Movers',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#001ea3',
  manifestThemeColor: '#001ea3',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading:
    'Best Movers get 100% free moving quotes from local long distance movers near you. ',
  subHeading:
    'Get long distance moving companies near you. Get 100% free moving quotes from cross country movers.',
  // social
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com',
    },
  ],
  phone: '000-00000',
  address: '469 Lawrence Dr. New York, NY 10016',
};
