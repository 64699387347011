import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <section id="footer">
      <a name="moving-quote"></a>
      <div className="inner">
        <h2 className="major">Get my quote</h2>
        <p>
          Please fill in this information so that one of our agents can help you
          plan you move now. We will contact you as soon as possible and look
          forward to getting your move scheduled, and getting you into your new
          home.
        </p>
        <form method="post" action="/#">
          <div className="fields">
            <div className="field">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="telephone">Telephone Number</label>
              <input type="tel" name="telephone" id="telephone" />
            </div>
            <div className="field">
              <label htmlFor="pickup">Pickup Zipcode</label>
              <input type="number" name="pickup" id="pickup" />
            </div>
            <div className="field">
              <label htmlFor="delivery">Delivery Zipcode</label>
              <input type="number" name="delivery" id="delivery" />
            </div>
            <div className="field">
              <label htmlFor="rooms">How many rooms?</label>
              <select name="rooms" id="rooms">
                <option value="partial">partial</option>
                <option value="studio">studio</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5+">5 +</option>
                <option value="commercial">commercial</option>
              </select>
            </div>

            <div className="specialty-items">
              {' '}
              <div>
                <input
                  type="checkbox"
                  name="auto-shipping"
                  id="auto-shipping"
                />
                <label htmlFor="auto-shipping">Auto Shipping</label>
              </div>
              <div>
                <input type="checkbox" name="piano-moving" id="piano-moving" />
                <label htmlFor="piano-moving">Piano Moving</label>
              </div>
              <div></div>
            </div>
            <div className="field">
              <label htmlFor="message">Anything you want to tell us?</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                placeholder="Is there a best time to call? Have an 8 foot pool table? Need movers ASAP? Let us know so we can put our best foot forward"
              ></textarea>
            </div>
          </div>
          <ul className="actions ">
            <li>
              <input type="submit" value="Get my Quote" />
            </li>
          </ul>
        </form>
        <ul className="contact">
          <li className="fa-home">{config.address}</li>

          <li className="fa-phone">{config.phone}</li>

          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url}>{url}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
